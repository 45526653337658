<template>
  <div>
    <section ref="release" class="pt-5 bg-section">
      <div class="container">
        <div class="row ">
          <div class="col-lg">
            <img :style="{height: 'auto','max-width': '100%' }" src="@/assets/images/autora/autora2.png" />
          </div>
          <div class="col-lg">
            <div class="d-flex align-items-center h-100">
              <p>
                Eu sou Tais Silva, psicóloga e autora, sendo uma mulher guerreira e sonhadora, cresci em uma família humilde, mas nunca desisti dos meus sonhos. Me tornar psicóloga foi o primeiro deles. No ano de 2019 a pândemina se espalhou pelo mundo. No início de 2020, confinada em casa eu me questionava sobre o que poderia fazer. Comecei a ler livros em francês, com o objetivo de aprender a língua, mas o desejo de fazer algo novo crescia cada vez mais dentro de mim, senti no fundo do meu coração e me perguntei "e se eu escrevesse?" É claro que descartei a ideia, mas o sentimento continuou crescendo. Comentei com meu marido e ele sabiamente me aconselhou a tentar, foi aí que comecei meu primeiro livro: Amar Sob a Luz da Lua, um romance recheado de emoções, que vai fazer você se apaixonar.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<style>
</style>
<script>
// @ is an alias to /src
export default {
  name: 'HomeSection2',
  components: {
  },
  data(){
    return {
    }
  }
}
</script>
