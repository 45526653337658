<template>
  <div class="">
    <div class="row">
      <div class="col-12 banner px-5 text-center  " style="vertical-align: bottom">
          <span>Tais Mota da Silva</span>
          <img style="height: 3rem; width: auto" class="mx-2" src="@/assets/images/simbolo.png">
      </div>
      <div class="col-12 ">
        <nav class="navbar navbar-expand-lg bg-body">
          <div class="container">
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse " id="navbarNav">
              <div class="w-100 d-flex align-items-center justify-content-between">
                <ul class="navbar-nav font-nexa">
                  <li class="nav-item">
                    <a class="nav-link active" aria-current="page" href="#" v-on:click="go('/')"><h5>Home</h5></a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link active" aria-current="page" href="#" v-on:click="go('/works')"><h5>Minhas obras</h5></a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link active" aria-current="page" href="#" v-on:click="go('/releases')"><h5>Próximos Lançamentos</h5></a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link active" aria-current="page" href="#" v-on:click="go('/about')"><h5>Sobre a autora</h5></a>
                  </li>
                </ul>

                <div>
                  <img style="width: 30px; height: auto" src="@/assets/images/logos/instagram.png"> @autorataissilva
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <div class="col-12"  :style="{'min-height': '100vh'}">
        <router-view>
        </router-view>
      </div>
    </div>
    <div class="row bg-dark h-100">
      <div class="col-12 p-1 text-center text-white">
        COPYRIGHT © 2024 Tais Silva
      </div>
    </div>
  </div>
</template>
<style>
  .banner{
    font-size: 3rem;
    padding: 10px;
    font-family: 'honeybee';
    /*background-image: url('@/assets/images/banner.jpg');*/
    /*background-size: cover;*/
    /*background-repeat: no-repeat;*/
    /*background-position: right;*/
  }
  .navbar-nav .nav-link:hover{
    border-bottom: 1px solid mistyrose;
    filter: invert(30%);
  }
  .navbar{
    font-family: 'Times New Roman';
    font-size: 1rem !important;
  }
</style>
<script>

export default {
  name: 'LayoutView',
  components: {

  },
  methods:{
    go: function(route){
      this.$router.push(route)
    }
  }

}
</script>
