<template>
<div class="menssage-autora position-relative bg-body">
  <div class="img-background-message" :style="{'background-image': 'url('+background+')'}">

  </div>
  <div class="row align-items-center justify-content-center">
    <div class="rounded-circle col-auto mx-1 rounded " :style="{height: '30px', width: '30px'}">

    </div>
    <div class="col">
      <slot></slot>
      <span class="assinatura float-end">Ass: Tais</span>
    </div>
  </div>
</div>
</template>
<style>
.img-background-message{
  position: relative;
  top: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.3 !important;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 3px dotted black;
}
.rounded{
  border: 1px solid black;
  opacity: 0.5;
}
.menssage-autora{
  font-family: "Gabriola";
  font-size: 1.3rem;
  z-index: 2;

  /*font-weight: 20px;*/
}
</style>
<script>
// @ is an alias to /src
import imgBackgroundMessage from '@/assets/images/fundos/flowers4.jpg';
export default {
  name: 'MensagemAutora',
  props: {
    background: {required: false, default: imgBackgroundMessage},
  },
  components: {

  },
  data(){
    return {
    }
  }
}
</script>
