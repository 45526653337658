<template>
  <div>
    <section ref="release" class="p-5">
      <div class="container">
        <h5>Leia agora com o livro digital</h5>
        <div class="row">
          <div class="col-lg p-0 livrodigital position-relative">
            <div>
              <img style="max-width: 100%; height: auto"  src="@/assets/images/livros/livrodigital.jpg">
            </div>
            <div class=" w-100 bg-dark" :style="{
              // 'background-color': 'RGB(0,0,0)'
            }">
              <div class="row">
                <div class="col-lg-12 text-center text-white p-3 ">
                  <h2 style="font-weight: bold">de R$ <s>25,00</s> por R$ 15,99</h2>
                  <h5 style="font-weight: bold">Aproveite este desconto por tempo limitado</h5>
                  <a :href="'www.amazon.com.br/Amar-sob-luz-lua-Coincid%C3%AAncia-ebook/dp/B0CZ1CT6YQ'"><button class="btn btn-primary">Comprar agora</button></a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg d-flex align-items-center justify-content-center">
            <div class="row align-items-center justify-content-center">
              <div class="col-lg-6 rounded-5 p-2" :style="{'max-width': '400px', height: '200px'}" v-for="(links,index) in cardsLinksLivroDigital" :key="index">
                <card-link-livro-digital :link="links.link" :image="links.image"></card-link-livro-digital>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<style>
.quadro{
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}
.livrodigital{
  /*box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;*/
}
.livroDigitalLink{
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}
</style>
<script>
// @ is an alias to /src
import ClubeDeAutores from  '@/assets/images/logos/clube-de-autores.png';
import GoogleBooks from  '@/assets/images/logos/Google_Books.png';
import Amazon from  '@/assets/images/logos/amazon.png';
import Kobo from '@/assets/images/logos/kobo.png';
import CardLinkLivroDigital from "@/views/Home/CardLinkLivroDigital.vue";
export default {
  name: 'HomeSection3',
  components: {
    CardLinkLivroDigital
  },
  data(){
    return {
      cardsLinksLivroDigital: {
        1: { link: 'www.amazon.com.br/Amar-sob-luz-lua-Coincid%C3%AAncia-ebook/dp/B0CZ1CT6YQ', image: Amazon },
        2: { link: 'clubedeautores.com.br/livro/amar-sob-a-luz-da-lua-2', image: ClubeDeAutores },
        3: { link: 'play.google.com/store/search?q=9786500905489&c=apps', image: GoogleBooks },
        4: { link: 'www.kobo.com/br/pt/search?query=9786500905489', image: Kobo },
      }
    }
  }
}
</script>