<template>
  <div>
    <section ref="release" class="bg-section" >

      <div class="container">
        <div class="row  pt-5 justify-content-center m-0">
          <div class="col-lg-auto  text-center" :style="{'z-index': 10}">
            <img src="@/assets/images/livros/amarsobaluzdalua.jpg" class="img-capa-livro book-border" />
          </div>
          <div class="col">
            <div class="row m-0">
              <div class="col-12 pt-3">
                <h3 class="titulo text-center">Amar Sob a Luz da Lua</h3>
                <p class="text-justify">
                  Laila é uma garota doce e gentil, que cansou da frieza de sua mãe, das brigas frequentes dos pais e das provocações de sua irmã mais velha. Então ela decide fazer suas próprias escolhas. Por isso, aceita o convite de sua amiga para irem a uma festa, onde ela se encontrava muito feliz, até que um jovem se esbarra nela, suja seu vestido e a culpa por isso, o que a deixa furiosa. Por coincidência ou destino, esse jovem rapaz lindo e confiante cruza novamente seu caminho. E o inesperado acontece, Laila se vê perdidamente apaixonada por ele, porém se recusa a se entregar a essa paixão. Contudo, não resiste pormuito tempo, pois o jovem confiante, que também se apaixona por ela, não desiste dos seus desejos, e ele a desejava. Laila se entrega loucamente a essa paixão, sem se preocupar se podia confiar nele ou não. Aos poucos, ela finalmente aceita ser amada. O que será que o destino reserva para esse jovem casal que está perdidamente apaixonado?
                </p>
              </div>
              <div class="col-12">
                <mensagem-autora>
                  Não permita que o medo te impeça de aproveitar o presente,
                  não deixe as oportunidades passarem,
                  escreva a sua história e deixe a sua marca no mundo.
                </mensagem-autora>
              </div>
              <div class="text-end position-relative py-5" :style="{'background-color': 'rgba(255, 203, 219, 0.5)'}">
                <div class="w-100 h-100 position-absolute" :style="{top: 0,left: '-100%', 'background-color': 'rgba(255, 203, 219, 0.5)'}">
                </div>
                <div class="col-lg ">
                  <div class="row  align-items-center justify-content-center">
                    <div class="col-auto p-1">
                      <button-link link="www.amazon.com.br/s?k=9786500905489" :logo="logoAmazon"></button-link>
                    </div>
                    <div class="col-auto p-1">
                      <button-link link="www.americanas.com.br/busca/9786500905489" :logo="logoAmericanas"></button-link>
                    </div>
                    <div class="col-auto p-1">
                      <button-link link="www.submarino.com.br/busca/9786500905489" :logo="submarino"></button-link>
                    </div>
                    <div class="col-auto p-1">
                      <button-link link="clubedeautores.com.br/livro/amar-sob-a-luz-da-lua-2" :logo="logoClubeDeAutores"></button-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<style>
.img-capa-livro{
  max-width: 300px;
  width: 100%;
  height: auto;
}
.img-background-message{
  position: absolute;
  top: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.6;
  height: 100%;
  width: 100%;
  z-index: -1;
  border: 3px dotted blue;
}
</style>
<script>
// @ is an alias to /src
import MensagemAutora from "@/components/MensagemAutora.vue";
import {left} from "core-js/internals/array-reduce";
import ButtonLink from "@/components/ButtonLink.vue";
import LogoAmazon from '@/assets/images/logos/amazon.png';
import LogoAmericanas from '@/assets/images/logos/americanas.png';
import LogoClubeDeAutores from '@/assets/images/logos/logo-clube-de-autores.png';
import LogoGoogleBooks from '@/assets/images/logos/Google_Books.png';
import Kobo from '@/assets/images/logos/kobo.png';
import MercadoLivre from '@/assets/images/logos/mercado-livre.png';
import Submarino from '@/assets/images/logos/submarino.png';
import Wattpad from '@/assets/images/logos/wattpad_logo.png';
import Eduzz from '@/assets/images/logos/eduzz-logo.png';
import GoogleBooks from '@/assets/images/logos/googleBooks.png';
export default {
  name: 'HomeSection1View',
  methods: {left},
  components: {
    MensagemAutora,ButtonLink
  },
  data(){
    return {
      logoAmazon: LogoAmazon,
      logoAmericanas: LogoAmericanas,
      logoMercadoLivre: MercadoLivre,
      logoClubeDeAutores: LogoClubeDeAutores,
      logoSubmarine: Submarino,
      logoGoogleBooks: LogoGoogleBooks,
      kobo: Kobo,
      mercadoLivro: MercadoLivre,
      submarino: Submarino,
      wattpad: Wattpad,
      eduzz: Eduzz,
      googleBooks: GoogleBooks,
    }
  }
}
</script>
