<template>
  <a :href="'//'+link"  target="_blank">
    <div class="card border buttonLink position-relative btn btn-light">
    <img class="img p-3" :src="logo">
    </div>
  </a>
</template>
<style>
.img{
  height: auto;
  width: 100%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.buttonLink{
  height: 80px;
  width: 160px;
  position: relative;
}
</style>
<script>
// @ is an alias to /src
export default {
  name: 'ButtonLink',
  props: {
    logo: {required: true},
    link: {required: true},
  },
  components: {

  },
  data(){
    return {
    }
  }
}
</script>
