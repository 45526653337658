<template>
  <div>
    <section ref="release">
      <div class="card container">
        <div class="row">
          <div class="col-auto p-5" style="max-width: 300px">
            <img src="@/assets/images/livros/amarsobaluzdalua.jpg" class="img-capa-livro book-border book" />
          </div>
          <div class="col-lg p-4">
            <h3 class="titulo text-center">Amar Sob a Luz da Lua</h3>
            <p class="text-justify">
              Laila é uma garota doce e gentil, que cansou da frieza de sua mãe, das brigas frequentes dos pais e das provocações de sua irmã mais velha. Então ela decide fazer suas próprias escolhas. Por isso, aceita o convite de sua amiga para irem a uma festa, onde ela se encontrava muito feliz, até que um jovem se esbarra nela, suja seu vestido e a culpa por isso, o que a deixa furiosa. Por coincidência ou destino, esse jovem rapaz lindo e confiante cruza novamente seu caminho. E o inesperado acontece, Laila se vê perdidamente apaixonada por ele, porém se recusa a se entregar a essa paixão. Contudo, não resiste pormuito tempo, pois o jovem confiante, que também se apaixona por ela, não desiste dos seus desejos, e ele a desejava. Laila se entrega loucamente a essa paixão, sem se preocupar se podia confiar nele ou não. Aos poucos, ela finalmente aceita ser amada. O que será que o destino reserva para esse jovem casal que está perdidamente apaixonado?
            </p>
            <div class="row mb-3 bg-section p-2  align-items-center justify-content-center">
              <div class="col-auto">
                <img src="@/assets/images/livros/amarsobaluzdalua.jpg" class="tipoDeLivro" />
              </div>
              <div class="col-lg ">
                <div class="row  align-items-center justify-content-center">
                  <div class="col-auto p-1">
                    <button-link link="www.amazon.com.br/s?k=9786500905489" :logo="logoAmazon"></button-link>
                  </div>
                  <div class="col-auto p-1">
                    <button-link link="www.americanas.com.br/busca/9786500905489" :logo="logoAmericanas"></button-link>
                  </div>
                  <div class="col-auto p-1">
                    <button-link link="www.submarino.com.br/busca/9786500905489" :logo="submarino"></button-link>
                  </div>
                  <div class="col-auto p-1">
                    <button-link link="clubedeautores.com.br/livro/amar-sob-a-luz-da-lua-2" :logo="logoClubeDeAutores"></button-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="row bg-section p-2 align-items-center justify-content-center">
              <div class="col-auto">
                <img src="@/assets/images/livros/amarsobaluzdalualivrokindle.png" class="tipoDeLivro" />
              </div>
              <div class="col-lg">
                <div class="row align-items-center justify-content-center">
                  <div class="col-auto p-1">
                    <button-link link="www.amazon.com.br/s?k=9786500905489" :logo="logoAmazon"></button-link>
                  </div>
                  <div class="col-auto p-1">
                    <button-link link="clubedeautores.com.br/livro/amar-sob-a-luz-da-lua-2" :logo="logoClubeDeAutores"></button-link>
                  </div>
                  <div class="col-auto p-1">
                    <button-link link="play.google.com/store/search?q=9786500905489&c=apps" :logo="googleBooks"></button-link>
                  </div>
                  <div class="col-auto p-1">
                    <button-link link="www.kobo.com/br/pt/search?query=9786500905489" :logo="kobo"></button-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<style>
.tipoDeLivro{
  max-height: 100px;
  /*max-width: 150px;*/
  width: auto;
}
</style>
<script>
import ButtonLink from "@/components/ButtonLink.vue";
import LogoAmazon from '@/assets/images/logos/amazon.png';
import LogoAmericanas from '@/assets/images/logos/americanas.png';
import LogoClubeDeAutores from '@/assets/images/logos/logo-clube-de-autores.png';
import LogoGoogleBooks from '@/assets/images/logos/Google_Books.png';
import MercadoLivre from '@/assets/images/logos/mercado-livre.png';
import Submarino from '@/assets/images/logos/submarino.png';
import Wattpad from '@/assets/images/logos/wattpad_logo.png';
import Kobo from '@/assets/images/logos/kobo.png';
import GoogleBooks from '@/assets/images/logos/googleBooks.png';
export default {
  name: 'AmarSobALuzDaLuaWork',
  components: {
    ButtonLink
  },
  data(){
    return {
      logoAmazon: LogoAmazon,
      logoAmericanas: LogoAmericanas,
      logoMercadoLivre: MercadoLivre,
      logoClubeDeAutores: LogoClubeDeAutores,
      logoSubmarine: Submarino,
      logoGoogleBooks: LogoGoogleBooks,
      mercadoLivro: MercadoLivre,
      submarino: Submarino,
      wattpad: Wattpad,
      kobo: Kobo,
      googleBooks: GoogleBooks,
    }
  }
}
</script>
