<template>
    <div class="position-relative h-100 w-100 livroDigitalLink">
      <div class="h-100 d-flex align-items-end justify-content-center p-3">
        <a :href="'//'+link"  target="_blank"><button class="btn btn-primary">Ler agora</button></a>
      </div>
      <img class="position-absolute p-5" :src="image" :style="{'z-index': -1, left: 0,top: 0,'max-height': '100%', 'max-width': '100%'}" />
    </div>
</template>
<style>
.livroDigitalLink{
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}
</style>
<script>
// @ is an alias to /src
export default {
  name: 'CardLinkLivroDigital',
  props:{
    image: {required: true},
    link: {required: true}
  },
  components: {
  },
  data(){
    return {
    }
  }
}
</script>