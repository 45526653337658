import { createApp } from 'vue'
import App from '@/App.vue'
import '@/registerServiceWorker'
import router from '@/router'
import VueSweetalert2 from "vue-sweetalert2";
const bootstrap = require('bootstrap');

export default
createApp(App, {
    data(){
        return {
            bootstrap: bootstrap
        }
    }
})
.use(router)
.use(VueSweetalert2)

