<template>
  <div>
    <home-section1></home-section1>
    <home-section3></home-section3>
<!--    <home-section4></home-section4>-->
    <home-section2></home-section2>
<!--    <home-section5></home-section5>-->
  </div>
</template>
<style>

</style>
<script>
// @ is an alias to /src

import HomeSection1 from "@/views/Home/Section1.vue";
import HomeSection3 from "@/views/Home/Section3.vue";
// import HomeSection4 from "@/views/Home/Section4.vue";
// import HomeSection5 from "@/views/Home/Section5.vue";
import HomeSection2 from "@/views/Home/Section2.vue";

export default {
  name: 'HomeView',
  components: {
    HomeSection2,
    // HomeSection5,
    // HomeSection4,
    HomeSection3,
    HomeSection1
  }
}
</script>
