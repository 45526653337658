<template>
  <div class="bg-section">
    <div class="container">
      <div class="row p-5 justify-content-center align-items-center">
        <div class="col-auto">
          <div class="p-5 position-relative">
            <img src="@/assets/images/livros/amarsobaluzdalua.jpg" class="img-capa-livro book-border book" />
            <div class=" p-3 font-ambarella bookTitle" ><h3 class="p-0 m-0">Amar sob a luz da lua 2</h3></div>
          </div>
        </div>
        <div class="col-auto">
          <div class="p-5 position-relative">
            <img src="@/assets/images/livros/amarsobaluzdalua.jpg" class="img-capa-livro book-border book" />
            <div class=" p-3 font-ambarella bookTitle" ><h3 class="p-0 m-0">Amar sob a luz da lua 3</h3></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
 .bookTitle{
    position: absolute;
    bottom: 0;
   background-color: rgb(0,0,0,0.8);
   color: white;
   font-weight: bold;
   transform: translateY(-80%);
 }
</style>
<script>
export default {
  name: 'ReleasesView',
  components: {
  }
}
</script>
