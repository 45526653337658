<template>
  <div class="bg-section p-4">
    <div class="container">
      <amar-sob-a-luz-da-lua-work></amar-sob-a-luz-da-lua-work>
    </div>
  </div>
</template>

<script>
import AmarSobALuzDaLuaWork from "@/views/Works/AmarSobAluzDaLua.vue";

export default {
  name: 'WorksView',
  components: {
    AmarSobALuzDaLuaWork
  }
}
</script>
