<template>
  <div id="#app">
    <router-view></router-view>
  </div>
</template>
<style lang="scss">
  @import '@/config/app.scss';
</style>
<script>
// @ is an alias to /src

export default {
  name: 'App',
  components: {
  }
}
</script>
