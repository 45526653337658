<template>
  <div class="container">
    <div class="row mt-5 align-items-center justify-content-center">
      <div class="col p-2 text-center">
        <div class="row">
          <div class="col-auto card px-3 pt-3" style="box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;">
            <img class="border" src="@/assets/images/autora/autora4.jpg" :style="{'max-width': '400px', height: 'auto'}">
            <img class="p-2" src="@/assets/images/separador.png" :style="{'max-width': '400px', height: 'auto'}">
          </div>
        </div>
      </div>
      <div class="col text-justify">
        <h5>
          Tais Mota da Silva nasceu em São Paulo, tem 29 anos e é casada com o amor da sua vida. No ano de 2018, se
          formou em Psicologia pela UNISA (Universidade Santo Amaro).É uma mulher guerreira e sonhadora. Ama ler e se
          apaixonou pela escrita, algo que nunca se imaginou fazendo, mas que não consegue mais viver sem.
        </h5>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'AboutView',
  components: {
  }
}
</script>
