import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LayoutView from "@/views/LayoutView.vue";
import WorksView from "@/views/WorksView.vue";
import AboutView from "@/views/AboutView.vue";
import ReleasesView from "@/views/ReleasesView.vue";

const routes = [
  {
    path: '/',
    name: 'layout',
    component: LayoutView,
    children: [
      {
        path: '',
        name: 'Home',
        component: HomeView
      },
      {
        path: '/works',
        name: 'works',
        component: WorksView
      },
      {
        path: '/about',
        name: 'about',
        component: AboutView
      },
      {
        path: '/releases',
        name: 'releases',
        component: ReleasesView
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
